import 'core-js/stable'; // polyfills for ECMAScript up to 2020
import 'regenerator-runtime/runtime'; // Standalone runtime for Regenerator-compiled generator and async functions
import 'bootstrap'; // Include Bootstrap JS

import ko from 'knockout';
import moment from 'moment';
import KnockoutBindingProvider from '@/knockoutBindingProvider';
import BindingsRegistration from '@/registrationBindings';
import ComponentRegistration from '@/registrationComponents';
import ValidationRulesRegistration from '@/registrationValidationRules';
import portalStartupErrorHandler from './portalStartupErrorHandler';
import TokenExpTimer from '@/Utils/TokenExpTimer';
import logger from '@/Utils/logger';
import router from '@/Utils/router';
import redirectHelper from '@/Utils/redirectHelper';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import languageHelper from '@/Utils/languageHelper';
import currentUserRepository from '@/Repositories/currentUserRepository';
import resourceDataRepository from '@/Repositories/resourceDataRepository';
import systemSettingsRepository from '@/Repositories/systemSettingsRepository';
import uploadManager from '@/Utils/uploadManager';
import contextData from '@/contextData';
import { ErrorCode } from '@/Types/Enums/errorCode';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';

import 'knockout.validation';
import '@/Utils/windowErrorHandler';

/**
 * This module retrieves data and sets state ready before binding Knockout to the DOM for the portal SPA.
 * This module is called from "portalAuthentication" after it's been confirmed a user is authenticated.
 */
function PortalStartup() {

  const self = this;
  self.sessionExpiredOverlayVisible = ko.observable(false);

  /**
   * @param {LanguageDetails} authTokenLanguageDetails
   * @return {Promise<void>}
   */
  self.init = async function (authTokenLanguageDetails) {
    BindingsRegistration.registerAllBindings();
    ComponentRegistration.registerAllComponents();
    ValidationRulesRegistration.registerValidationRules();

    // Clear a registration ID that may exist as it's not applicable when logging in to the portal (multiple users using same machine)
    localStorageHelper.clearSessionValue(LocalStorageKeyType.AuthenticationRegistrationId);

    const portalStartupErrorType = await retrieveAndPersistUserData(authTokenLanguageDetails);

    if (portalStartupErrorType !== PortalStartupErrorType.None) {
      portalStartupErrorHandler.execute(portalStartupErrorType);
      return;
    }

    retrieveAndPersistResourceData()
      .then(retrieveAndPersistPortalSettings)
      .then(() => {

        try {
          setMomentLocale();
          router.init();
          applyBindings();

          consoleLogInfo(contextData.portalSettings, contextData.languageCode());

        } catch (e) {
          logUnexpectedError(e);
        }

      })
      .catch(e => {
        logUnexpectedError(e);
      });

    TokenExpTimer.initAuthTokenExpirationTimer(() => {
      if (uploadManager.uploadsCount()) {
        self.sessionExpiredOverlayVisible(true);
      }
    });
  };

  /**
   * @param {LanguageDetails} authTokenLanguageDetails
   * @return {Promise<*>}
   */
  function retrieveAndPersistUserData(authTokenLanguageDetails) {
    const errorCodesToIgnore = [ErrorCode.BusinessDisabled, ErrorCode.UnknownUser];
    return currentUserRepository.getUserData(errorCodesToIgnore)
      .then(function (userData) {

        console.groupCollapsed('Authenticated user details');
        console.info('Name:', userData.displayName());
        console.info('Email address:', userData.emailAddress());
        console.info('Business:', userData.business.businessName);
        console.info('Is fully registered:', userData.isFullyRegistered);
        console.info('Can complete registration:', userData.canCompleteRegistration);
        console.groupEnd();

        if (!userData.isFullyRegistered) {
          if (userData.canCompleteRegistration) {
            // The user is not fully registered but is allowed to complete their registration so redirect them to
            // the registration page.
            console.info(
              `The user ${userData.displayName()} (${userData.emailAddress()}) is not fully registered
              so redirecting to the registration page`
            );
            redirectHelper.redirectToPath('/registration.html?id=' + userData.registrationId);
          } else {
            logger.logAndThrowUntranslatedError(
              'There is a problem with your user account. Please try again later.',
              'The user with email address \'' + userData.emailAddress() + '\' is not correctly registered.');
            return PortalStartupErrorType.UnknownErrorGettingUserData;
          }
        }

        const languageDetailsArray = [authTokenLanguageDetails, userData.languageDetails, localStorageHelper.getLanguageDetails()];
        const languageCode = languageHelper.getNewestLanguageCodeOrFirstSupported(languageDetailsArray);
        contextData.languageCode(languageCode);
        contextData.userData = userData;

        // If the authentication token contains a language code that is different to the user's data then
        // update the user's language code. The user actively selected a language during authentication
        // and the Business Portal should honour this.
        if (languageCode.toLowerCase() !== userData.languageDetails.languageCode.toLowerCase()) {
          currentUserRepository.updateLanguageCode(languageCode);
        }

        return PortalStartupErrorType.None;
      })
      .catch(function (jqXhr) {
        if (jqXhr.status === 403 && jqXhr.errorCode === ErrorCode.BusinessDisabled) {
          return PortalStartupErrorType.BusinessDisabled;
        }

        if (jqXhr.status === 403 && jqXhr.errorCode === ErrorCode.UnknownUser) {
          return PortalStartupErrorType.UnknownUser;
        }

        if (jqXhr.status === 401) {
          return PortalStartupErrorType.AuthorisationError;
        }

        let errorMessage = 'An error occurred loading the site. Please try again later. ';

        if (jqXhr.serverErrorMessages) {
          errorMessage += jqXhr.serverErrorMessages.join(' ');
        }

        if (jqXhr.status === 404) {
          errorMessage += 'A user account doesn\'t exist or is incorrectly configured.';
        }

        // As an error occurred when retrieving the user's data, we won't know the language to display an error
        // to the user in and we won't have the translated text. All we can do here is display error in English.
        logger.logAndThrowUntranslatedError(
          errorMessage,
          // ReSharper disable once ExpressionIsAlwaysConst
          null,
          jqXhr);

        return PortalStartupErrorType.UnknownErrorGettingUserData;
      });
  }

  function retrieveAndPersistResourceData() {

    return resourceDataRepository.getResourceData(contextData.languageCode())
      .then(function (resourceData) {
        contextData.resourceData = resourceData;
      })
      .catch(function (jqXhr) {

        // As an error occurred retrieving the translated text, all we can do here is display error in English
        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to get the resource data / translated text',
          jqXhr);

        return Promise.reject(jqXhr);
      });
  }

  function retrieveAndPersistPortalSettings() {

    return systemSettingsRepository.getPortalSettings()
      .then(function (portalSettings) {
        contextData.inReleaseMode = portalSettings.inReleaseMode;
        contextData.portalSettings = portalSettings;
        contextData.availableLanguages(languageHelper.getAvailableLanguages(portalSettings.availableLanguageCodes));
      })
      .catch(function (jqXhr) {

        logger.logAndThrowUntranslatedError(
          'An error occurred loading the site. Please try again later.',
          'Unable to get the system portal settings',
          jqXhr);

        return Promise.reject(jqXhr);
      });
  }

  function setMomentLocale() {
    moment.locale(contextData.languageCode());
  }

  function consoleLogInfo(portalSettings, languageCode) {

    console.log('Thanks for visiting!');
    console.log('Version:', portalSettings.versionNumber);
    console.log('In release mode:', portalSettings.inReleaseMode);
    console.log('Language code:', languageCode);
  }

  function applyBindings() {

    KnockoutBindingProvider.registerSecureBindingProvider();

    ko.validation.init({
      insertMessages: false,
      decorateElement: true,
      errorElementClass: 'has-error'
    });

    const portalErrorElement = document.getElementById('portalError');
    portalErrorElement?.parentElement.removeChild(portalErrorElement);

    ko.applyBindings({
      route: router.currentRoute,
      pageTitle: router.pageTitle,
      disablePageComponent: router.disablePageComponent,
      languageCode: contextData.languageCode(),
      sessionExpiredOverlayVisible: self.sessionExpiredOverlayVisible
    }, document.getElementsByTagName('html')[0]);
  }

  function logUnexpectedError(e) {
    logger.logAndThrowUntranslatedError(
      'An unexpected error occurred loading the site. Please try again later.',
      'An unexpected error occurred. Please review the error and determine if this needs to specifically be caught',
      e);
  }
}

export default new PortalStartup();
