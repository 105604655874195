export default class DiscussionMessageModel {
    sentTimestamp: Date;

    /** Flags if the discussion message was send by a user in this portal / system or not */
    acknowledgedTimestamp: Date | null;

    /** For local sender messages, flags if the message was acknowledged by the other system */
    localMessageAcknowledged: boolean;

    constructor(
      public senderDisplayName: string,

      /** This may contain unsafe HTML and MUST ALWAYS be HTML encoded e.g. use KO "text" binding */
      public content: string,
      public isLocalSender: boolean,

      private sentTimestampString: string,
      private acknowledgedTimestampString: string | null
    ) {
      this.sentTimestamp = new Date(this.sentTimestampString);
      this.acknowledgedTimestamp = acknowledgedTimestampString ? new Date(acknowledgedTimestampString) : null;
      this.localMessageAcknowledged = isLocalSender && !!acknowledgedTimestampString;
    }
}