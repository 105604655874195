import { mustBeTrue } from './Validation/mustBeTrueRule';
import ko from 'knockout';

class ValidationRulesRegistration {

  static registerValidationRules() {
    ko.validation.rules.mustBeTrue = mustBeTrue;

    ko.validation.registerExtenders();
  }
}

export default ValidationRulesRegistration;
