function FilterDataModel(id, primaryFilteredField, secondaryFilteredField) {

  const self = this;

  self.id = id;
  self.primaryFilteredField = primaryFilteredField;
  self.isMultipleFilteringMode = secondaryFilteredField !== undefined;
  self.secondaryFilteredField = secondaryFilteredField;
}

export default FilterDataModel;
