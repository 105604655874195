import { PersonaDisplayNameSummaryDto } from './personaDisplayNameSummaryDto';

export class GroupMemberDto {
    public isGroupPrimaryContact: boolean;
    public memberPersona: PersonaDisplayNameSummaryDto;

    constructor(personaId: string, personaDisplayName: string, isGroupPrimaryContact: boolean) {
      this.isGroupPrimaryContact = isGroupPrimaryContact;
      this.memberPersona = new PersonaDisplayNameSummaryDto(personaId, personaDisplayName);
    }
}
