import ko from 'knockout';
import resourceHelper from '../Utils/resourceHelper';
import constants from '../constants';

function MenuDataRepository() {

  var self = this;

  self.getRequestsMenuData = function () {

    return [
      {
        text: resourceHelper.getString('Active'),
        name: 'Active',
        isChild: false,
        count: ko.observable(null),
        isActive: ko.observable(false),
        data: {

          statuses: ['New', 'InProgress']
        }
      },
      {
        text: resourceHelper.getString('New'),
        name: 'New',
        isChild: true,
        count: ko.observable(null),
        isActive: ko.observable(false),
        data: {
          statuses: ['New']
        }
      },
      {
        text: resourceHelper.getString('InProgress'),
        name: 'InProgress',
        isChild: true,
        count: ko.observable(null),
        isActive: ko.observable(false),
        data: {
          statuses: ['InProgress']
        }
      },
      {
        text: resourceHelper.getString('Closed'),
        name: 'Closed',
        isChild: false,
        count: ko.observable(null),
        isActive: ko.observable(false),
        data: {
          statuses: ['Completed', 'Rejected', 'Cancelled', 'Failed']
        }
      }
    ];
  };

  self.getMyAccountMenuData = function (includeAdminMenu) {

    return [
      {
        text: resourceHelper.getString('UserDetails'),
        name: 'UserDetails',
        isChild: false,
        count: null,
        isActive: ko.observable(false),
        data: {
          pageComponentName: 'user-details'
        }
      },
      {
        text: resourceHelper.getString('ChangePassword'),
        name: 'ChangePassword',
        isChild: false,
        count: null,
        isActive: ko.observable(false),
        data: {
          pageComponentName: 'change-password'
        }
      },
      ...includeAdminMenu ? [
        {
          text: resourceHelper.getString('BusinessDetails'),
          name: 'BusinessDetails',
          isChild: false,
          count: null,
          isActive: ko.observable(false),
          data: {
            pageComponentName: 'business-details'
          }
        }] : []
    ];
  };

  self.getGroupDetailsTabs = function () {

    return [
      {
        text: resourceHelper.getString('UsersTabTitle'),
        name: constants.tabNames.usersTab,
        isActive: ko.observable(true),
        data: {
          pageComponentName: 'group-users-tab'
        }
      },
      {
        text: resourceHelper.getString('LocationTabTitle'),
        name: constants.tabNames.locationTab,
        isActive: ko.observable(false),
        data: {
          pageComponentName: 'group-location-tab'
        }
      }
    ];
  };
}

export default new MenuDataRepository();
