/**
 * Defines the different errors that can occur when the portal initially loads.
 * Each value requires a separate component in "Scripts/App/PageComponents/PortalError".
 */

export enum PortalStartupErrorType {
  None = 'None',
  BusinessDisabled = 'BusinessDisabled',
  UnknownUser = 'UnknownUser',
  UnknownErrorGettingUserData = 'UnknownErrorGettingUserData',
  AuthorisationError = 'AuthorisationError'
}
