import repositoryHelper from './repositoryHelper';

function UsersRepository() {
  const self = this;
  const baseResourceUrl = 'api/users/';

  /**
   * @param {string} businessId
   * @return {Promise}
   */
  self.getAllUsers = businessId => repositoryHelper.ajaxGet(baseResourceUrl + businessId);

  /**
  * @param {string} businessId
  * @return {Promise}
  */
  self.getAllPersonaSummaries = businessId => repositoryHelper.ajaxGet(`${baseResourceUrl}${businessId}/personasummary`);

  /**
   * @param {string} businessId
   * @param {string} personaId
   * @return {Promise}
   */
  self.getUserDetails = (businessId, personaId) => repositoryHelper.ajaxGet(`${baseResourceUrl}${businessId}/${personaId}`);

  /**
  * @param {string} businessId
  * @param {string} personaId
  * @param {object} userDetails
  * @return {Promise}
  */
  self.updateBusinessUserDetails = (businessId, personaId, userDetails) =>
    repositoryHelper.ajaxPut(baseResourceUrl + businessId + '/' + personaId, userDetails);

  /**
  * @param {string} businessId
  * @param {object} userDetails
  * @return {Promise}
  */
  self.createBusinessUser = (businessId, userDetails) => repositoryHelper.ajaxPost(`${baseResourceUrl}${businessId}`, userDetails);

  /**
   * @param {string} businessId
   * @param {string} personaId
   * @return {Promise}
   */
  self.deleteBusinessUser = (businessId, personaId) => repositoryHelper.ajaxDelete(baseResourceUrl + businessId + '/' + personaId);
}

export default new UsersRepository();
