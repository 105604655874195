import * as ko from 'knockout';
import { Observable, PureComputed } from 'knockout';
import { GroupMemberDto } from './groupMemberDto';
import { GroupDetailsDto } from './groupDetailsDto';
import resourceHelper from '../../Utils/resourceHelper';

export class GroupSummaryDto {
  membersSummary: PureComputed<string>;

  constructor(
    public readonly groupId: string,
    public groupDetails: GroupDetailsDto,
    public groupMembers: Observable<GroupMemberDto[]>
  ) {
    this.membersSummary = ko.pureComputed(() => this.getMembersSummary());

  }

  /**
   * Gets the count of members and primary contact as a human readable string
   * @return {string}
   */
  getMembersSummary(): string {
    const memberCount = this.groupMembers().length;
    const allPrimaryContacts = this.groupMembers().filter(m => m.isGroupPrimaryContact).length;
    let label = '';

    if (memberCount === 1) {
      label += `1 ${resourceHelper.getString('User').toLowerCase()}`;
    } else {
      label += `${memberCount} ${resourceHelper.getString('Users').toLowerCase()}`;
    }

    if (allPrimaryContacts === 1) {
      label += ` ${resourceHelper.getString('PrimaryGroupContactsSingleSummary')}`;
    } else if (allPrimaryContacts !== 0) {
      const replaceObj = { count: allPrimaryContacts };
      label += ` ${resourceHelper.getString('PrimaryGroupContactsMultipleSummary', replaceObj)}`;
    }

    return label;
  }
}
