import repositoryHelper from './repositoryHelper';
import AssociatedFileModel from '../Models/associatedFileModel';

function AssociatedFileRepository() {

  var self = this;

  var baseResourceUrl = 'api/associatedFile/';

  self.getAssociatedFileByRequestId = function (requestId) {

    var resourceUrl = baseResourceUrl + 'request/' + requestId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
        .then(function (result) {
          var assocaitedFilesModels = createAssociatedFilesModels(result);
          resolve(assocaitedFilesModels);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  self.downloadAssociatedFileById = associatedFileId => {
    var url = `${baseResourceUrl}/download/${associatedFileId}`;
    repositoryHelper.downloadPostRequest(url, { associatedFileId: associatedFileId });
  };

  function createAssociatedFilesModels(serverAssociatedFilesModels) {

    var serverAssociatedFileModel;
    var associatedFilesModels = [];

    for (var i = 0; i < serverAssociatedFilesModels.length; i++) {
      serverAssociatedFileModel = serverAssociatedFilesModels[i];
      associatedFilesModels.push(new AssociatedFileModel(serverAssociatedFileModel));
    }

    return associatedFilesModels;
  }
}

export default new AssociatedFileRepository();
