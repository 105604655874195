import ko from 'knockout';
import constants from '../constants';

/**
 * Model to hold details about an upload. The model should be created with the serverModel parameter
 * uploads that already exist and the model should be created with the nativeBrowserFile parameter for
 * new uploads that have not yet been created on the server.
 * @param {*} [serverModel] Upload model returned from the server
 * @param {*} [nativeBrowserFile] File object for a new upload
 */
function UploadModel(serverModel, nativeBrowserFile) {

  var self = this;
  var nativeBrowserFileExists = !!nativeBrowserFile; // Convert to boolean
  var originalCameraName = serverModel ? serverModel.cameraName : null;
  var originalComments = serverModel ? serverModel.comments : '';

  serverModel = serverModel || {};
  nativeBrowserFile = nativeBrowserFile || {};

  // Standard properties
  self.uploadId = serverModel.uploadId || null;
  self.requestId = serverModel.requestId || null;
  self.uploadToken = serverModel.uploadToken || null;
  self.addedTimestamp = serverModel.addedTimestamp || new Date().toUTCString();
  self.fileName = serverModel.fileName || nativeBrowserFile.name;
  self.fileSize = serverModel.fileSize || nativeBrowserFile.size;
  self.fileRelativePath = resolveFileRelativePath(serverModel, nativeBrowserFile);

  self.rootFolderName = self.fileRelativePath ?
    self.fileRelativePath.substr(0, self.fileRelativePath.indexOf('/')) : null;
  self.comments = ko.observable(serverModel.comments || '');
  self.nativeBrowserFile = nativeBrowserFileExists ? nativeBrowserFile : null;
  self.ajaxRequest = null;

  self.uploadStatuses = constants.uploadStatuses;
  self.uploadType = constants.uploadTypes.file;

  // Observables
  self.cameraName = ko.observable(serverModel.cameraName || null);
  self.statusName = ko.observable(serverModel.uploadStatusName || constants.uploadStatuses.authorising);
  self.progress = ko.observable(serverModel.uploadStatusName === constants.uploadStatuses.uploaded ? 100 : null);
  self.bytesUploaded = ko.observable(serverModel.bytesUploaded || 0);
  self.userIsCurrentlyUploading = ko.observable(false); // The user is currently actively uploading chunks

  // Computed observables
  self.hasChanged = ko.pureComputed(function () {
    return self.cameraName() !== originalCameraName || self.comments() !== originalComments;
  });

  self.isAuthorising = ko.pureComputed(function () {
    return self.statusName() === constants.uploadStatuses.authorising;
  });

  // TODO: Make the property name camera selected item
  // Observable for use as the selected item with the selectOrTextInput component. As the camera name is
  // just a string (with no Id) we just provide the camera name and subscribe to the selected item change
  // so we can update the name when changed.
  self.selectedItem = ko.observable({
    cameraName: serverModel.cameraName
  });

  self.selectedItem.subscribe(function (newValue) {
    self.cameraName(newValue ? newValue.cameraName : null);
  });

  /**
   * Populate upload Id, upload token and others once new server upload created, saved and returned
   * @param {*} serverModel
   */
  self.updateAfterServerModelCreated = function (serverModel) {

    self.uploadId = serverModel.uploadId;
    self.requestId = serverModel.requestId;
    self.uploadToken = serverModel.uploadToken;
    self.addedTimestamp = serverModel.addedTimestamp;
    self.statusName(serverModel.uploadStatusName);
  };

  /**
   * Once the ajax request for a file has been created after the server model is created (in order for
   * the upload token to have been generated) then set the ajax request which will be needed if a user
   * cancels a file upload.
   * @param {*} ajaxRequest Ajax request which can be used to cancel an upload
   */
  self.setAjaxRequest = function (ajaxRequest) {
    self.ajaxRequest = ajaxRequest;
  };

  self.setUploadStarted = function () {
    self.statusName(constants.uploadStatuses.uploading);
    self.progress(0);
    self.userIsCurrentlyUploading(true);
  };

  self.updateUploadProgress = function (progress, bytesUploaded) {
    self.progress(progress);
    self.bytesUploaded(bytesUploaded);
  };

  self.setUploadComplete = function () {
    self.statusName(constants.uploadStatuses.uploaded);
    self.progress(100);
    self.bytesUploaded(self.fileSize);
    self.userIsCurrentlyUploading(false);
  };

  self.setUploadFailed = function () {
    self.statusName(constants.uploadStatuses.failed);
    self.userIsCurrentlyUploading(false);
  };

  self.setUploadFailedAuthorisation = function () {
    self.statusName(constants.uploadStatuses.failedAuthorisation);
  };

  self.cancelUpload = function () {
    self.statusName(constants.uploadStatuses.cancelled);
    self.userIsCurrentlyUploading(false);

    if (self.ajaxRequest) {
      self.ajaxRequest.abort();
    }
  };

  function resolveFileRelativePath(serverModel, nativeBrowserFile) {
    // At the moment backend distinguishes cctv folders from other uploads by presence of relative.
    // The structure of zip files is then recovered from the relative path.
    // Until there is no infrastructure to recognise file, folder file and cctv folder the nativeBrowserFile.webkitRelativePath
    // is excluded from mapping and not sent to the backend
    if (nativeBrowserFile && nativeBrowserFile.isFile) {
      return '';
    }
    return serverModel.fileRelativePath || nativeBrowserFile.webkitRelativePath;
  }
}

export default UploadModel;
