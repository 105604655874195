import repositoryHelper from './repositoryHelper';

function CameraRepository() {

  var self = this;
  var baseResourceUrl = 'api/camera/';

  /**
   * @param {string} cameraId
   * @return {Promise}
   */
  self.getCamera = function (cameraId) {

    var resourceUrl = baseResourceUrl + cameraId;
    return repositoryHelper.ajaxGet(resourceUrl);
  };

  /**
   * @param {object} cameraData
   * @return {Promise}
   */
  self.updateCamera = function (cameraData) {

    var resourceUrl = baseResourceUrl + cameraData.cameraId;
    return repositoryHelper.ajaxPut(resourceUrl, cameraData);
  };

  /**
   * @param {object} cameraData
   * @return {Promise}
   */
  self.addNewCamera = function (cameraData) {
    return repositoryHelper.ajaxPost(baseResourceUrl, cameraData);
  };

  /**
   * @param {string} cameraId
   * @return {Promise}
   */
  self.deleteCamera = function (cameraId) {
    var resourceUrl = baseResourceUrl + cameraId;
    return repositoryHelper.ajaxDelete(resourceUrl);
  };
}

export default new CameraRepository();
