import { FilterItemDto } from '../Components/filterPanel/Models/filterItemDto';

export class FilteredRequestSummaryRequestDto {
  constructor(
        public readonly businessId: string,
        public readonly filterCriteria: FilterItemDto[],
        public sortField: string,
        public sortOrder: string,
        public readonly page: number) {
  }
}
