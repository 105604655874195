import ko from 'knockout';
import localStorageHelper from '@/Utils/localStorageHelper';

/**
 * Module to hold state for the SPA. This module should hold only state that is global / applicable to
 * the whole SPA such a language code and user details. Please carefully consider any properties added
 * to this module.
 */
function ContextData() {

  // TODO: Review if there should be a context data per SPA (e.g. portal, registration & reset password)

  const self = this;

  // Properties available regardless of the SPA type loaded (portal or registration)
  self.languageCode = ko.observable();
  self.availableLanguages = ko.observable();

  self.languageCode.subscribe(newValue => {
    // Sync the language code with local storage so when a user returns back to the Business Portal
    // but isn't yet authenticated, we can use the language code they previously had.
    localStorageHelper.setLanguageDetails(newValue);
  });

  /**
   * Returns the flag icon path for the current language (or country if the language code has a region / country)
   * @type {KnockoutComputed<string>}
   */
  self.flagIcon = ko.pureComputed(function () {
    const parts = self.languageCode().split('-');
    const countryCode = parts.length === 1 ? parts[0] : parts[1]; // Return "cy" from "cy" and "GB" from "en-GB"
    return '/Content/images/flags/' + countryCode.toLowerCase() + '.svg';
  }, self);

  self.inReleaseMode = null;

  /** Holds all the translated string from the resource file ClientMessages.resx */
  self.resourceData = null;

  /** UserModel - Contains user data if the Portal SPA is loaded */
  self.userData = null;

  /** Contains portal settings if the Portal SPA is loaded */
  self.portalSettings = null;

  // TODO: Why user context data here? Review using local storage helper and session value
  /** Holds the sort field and sort order for the requests screen */
  self.requestsSort = {
    sortField: 'RequestTimestamp',
    sortOrder: 'Desc'
  };

  // TODO: Why user context data here? Review using local storage helper and session value
  /** Holds the sort field and sort order for the cameras screen */
  self.camerasSort = {
    sortField: 'Name',
    sortOrder: 'Asc'
  };

  /** Contains registration data if the Registration SPA is loaded */
  self.registrationData = null;

  /** Contains reset password data if the Registration SPA is loaded */
  self.resetPasswordData = null;
}

export default new ContextData();
