/**
 * Holds a language code and the last updated date. This can be used to define a language code
 * from the authentication token, user details record and local storage data.
 *
 * As we can have multiple sources of the language code to use for a user, each source requires
 * the updated date so the newest source can be determined and used.
 */
interface LanguageDetails {
  languageCode: string,
  updatedDate: Date,
  source: LanguageDetailsSource
}

export enum LanguageDetailsSource {
  AuthToken = 'AuthToken',
  UserDetails = 'UserDetails',
  LocalStorage = 'LocalStorage'
}

export default LanguageDetails;
