import RequestModel from '../Models/requestModel';
import repositoryHelper from './repositoryHelper';

function RequestRepository() {

  var self = this;
  var baseResourceUrl = 'api/request/';

  self.getRequest = function (requestId) {

    var resourceUrl = baseResourceUrl + requestId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
        .then(function (request) {
          var requestModel = new RequestModel(request);
          resolve(requestModel);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  self.updateRequest = function (requestDataModel) {

    var resourceUrl = baseResourceUrl + requestDataModel.requestId + '?submitResponse=false';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  };

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  self.updateRequestAndSubmitResponse = function (requestDataModel) {
    var resourceUrl = baseResourceUrl + requestDataModel.requestId + '?submitResponse=true';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  };

}

export default new RequestRepository();
