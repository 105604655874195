import ko from 'knockout';
import BusinessModel from './businessModel';
import { LanguageDetailsSource } from '@/Types/languageDetails';

function UserModel(serverUserModel) {
  const self = this;

  // Observables
  self.givenName = ko.observable(serverUserModel.givenName);
  self.familyName = ko.observable(serverUserModel.familyName);
  self.displayName = ko.observable(serverUserModel.displayName);
  self.phoneNumber = ko.observable(serverUserModel.phoneNumber);
  self.emailAddress = ko.observable(serverUserModel.emailAddress);

  self.personaId = serverUserModel.personaId;
  self.userId = serverUserModel.userId;
  self.isBusinessAdminUser = serverUserModel.isBusinessAdminUser;
  self.updatedTimestamp = serverUserModel.updatedTimestamp;

  /**
   * The registration ID for a user. Only set when a user is pre-registered and approved to register.
   * @type {string}
   */
  self.registrationId = serverUserModel.registrationId;
  self.isFullyRegistered = serverUserModel.isFullyRegistered;
  self.canCompleteRegistration = serverUserModel.canCompleteRegistration;

  self.business = new BusinessModel(serverUserModel.business);

  /**
   * @type {LanguageDetails}
   */
  self.languageDetails = {
    languageCode: serverUserModel.languageCode,
    updatedDate: new Date(serverUserModel.updatedTimestamp),
    source: LanguageDetailsSource.UserDetails
  };
}

export default UserModel;
