import repositoryHelper from './repositoryHelper';

function AddressLookupRepository() {

  var self = this;
  var baseResourceUrl = 'api/addressLookup/';

  /**
   * @param {string} addressSearchTerm
   * @param {boolean} [orderByDistanceToCenter]
   * @return {Promise}
   */
  self.lookupAddress = function (addressSearchTerm, orderByDistanceToCenter) {

    orderByDistanceToCenter = (typeof orderByDistanceToCenter !== 'undefined') ? orderByDistanceToCenter : true;

    var resourceUrl =
      baseResourceUrl + '?addressSearchTerm=' + encodeURIComponent(addressSearchTerm) +
      '&orderByDistanceToCenter=' + orderByDistanceToCenter;

    return repositoryHelper.ajaxGet(resourceUrl)
      .then(function (addressSearchResults) {

        return new Promise(function (resolve) {
          resolve(addressSearchResults.slice(0, 10)); // Return the top 10 results
        });

      });
  };
}

export default new AddressLookupRepository();
