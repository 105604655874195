import localStorageHelper from './Utils/localStorageHelper';
import AuthenticationSettings from './Types/authenticationSettings';

/**
 * Class to hold fixed configuration settings for the SPA. There should be minimal settings
 * here as real settings should all be retrieved from the server via an AJAX call.
 */

export default class Config {
  public static uploadMaxChunkSize = 3670016; // 3.5 MB in bytes

  /** Content from resource files is wrapped in square brackets / pipe character */
  public static wrapTranslatedTextInBrackets = false;

  /** Flags whether the debug message should be displayed in the toastr notification when there's an error  */
  public static showErrorDebugMessage = true;

  public static periodTillInactiveWarningInMinutes = 15;
  public static periodTillInactiveInMinutes = 20;

  public static loggingSettings = {
    /** Flags whether debug messages are logged to the console */
    handleDebug: true
  };

  public static supportedLanguages = [
    {
      resourceKey: 'LanguageNameEnglishUS',
      languageCode: 'en-US'
    },
    {
      resourceKey: 'LanguageNameEnglishGB',
      languageCode: 'en-GB'
    },
    {
      resourceKey: 'LanguageNameWelsh',
      languageCode: 'cy'
    },
    {
      resourceKey: 'LanguageNameTestLanguage',
      languageCode: 'vi-VN'
    }
  ];

  public static getTokenManagerSettingsForLogin(authenticationSettings: AuthenticationSettings): object {
    const redirectRelativePath = '/tokenLoginRedirect.html';
    return Config.getTokenManagerSettings(redirectRelativePath, authenticationSettings);
  }

  public static getTokenManagerSettingsForRegistration(authenticationSettings: AuthenticationSettings): object {
    const redirectRelativePath = '/tokenRegistrationRedirect.html';
    return Config.getTokenManagerSettings(redirectRelativePath, authenticationSettings);
  }

  private static getTokenManagerSettings(redirectRelativePath: string, authenticationSettings: AuthenticationSettings): object {
    const protocolAndDomain = window.location.protocol + '//' + window.location.host;
    const languageCode = localStorageHelper.getLanguageDetails().languageCode ?? authenticationSettings.defaultLanguageCode;

    console.debug(`The token manager settings 'ui_locales' property value is "${languageCode}"`);

    return {
      'authority': authenticationSettings.authority,
      'client_id': authenticationSettings.clientId,
      'redirect_uri': protocolAndDomain + redirectRelativePath,
      'post_logout_redirect_uri': protocolAndDomain + '/index.html',
      'response_type': 'id_token token',
      'scope': 'openid profile',
      'silent_redirect_uri': protocolAndDomain + '/tokenSilentRedirect.html',
      'silent_renew': true,
      'load_user_profile': true,
      'request_state_store': window.sessionStorage,
      'ui_locales': languageCode
    };
  }
}
