import * as ko from 'knockout';
import KnockoutSecureBinding from 'knockout-secure-binding';

export default class KnockoutBindingProvider {
  /**
   * Configure KO to use a "safe" binding so we can use a Content Security Policy (CSP).
   * Knockout uses "new Function" which violates the CSP rule "unsafe-eval" which
   * should not be excluded in the CSP.
   * For more information please take a look at https://github.com/brianmhunt/knockout-secure-binding.
   */
  static registerSecureBindingProvider(): void {
    // Configure KO to use a "safe" binding so we can use a Content Security Policy (CSP).
    // Knockout uses "new Function" which violates the CSP rule "unsafe-eval" which
    // should not be excluded in the CSP.
    // For more information please take a look at https://github.com/brianmhunt/knockout-secure-binding.
    ko.bindingProvider.instance = new KnockoutSecureBinding({
      attribute: 'data-bind', // default "data-sbind"
      globals: window, // default {}
      bindings: ko.bindingHandlers, // default ko.bindingHandlers
      noVirtualElements: false // default true
    });
  }
}
