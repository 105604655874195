import resourceHelper from '../Utils/resourceHelper';
import { DataRequestField } from './dataRequestField';

function ModelsHelper() {

  var self = this;

  self.mapDataRequestFields = function createDataRequestFields(serverDataRequestFields) {

    const cameraNames = serverDataRequestFields.requestedCameraNames ||
      resourceHelper.getString('ReferToRequestDescription');

    return new DataRequestField(
      serverDataRequestFields.requestedStartTime,
      serverDataRequestFields.requestedEndTime,
      cameraNames
    );
  };
}

export default new ModelsHelper();
