import ko from 'knockout';

/**
 *  @param {object} [groupDetails]
 */
function GroupDetailsValidationModel(groupDetails) {

  const self = this;

  // Observables for ko validation
  self.address = ko.observable();
  self.isLocationAssigned = ko.observable();
  self.coordinate = ko.observable();
  self.groupName = ko.observable();
  self.clientErrors = ko.validation.group(self, { deep: true });

  if (groupDetails) {
    populateObservables(groupDetails);
  }

  function populateObservables(groupDetails) {
    self.groupName(groupDetails.groupName);
    self.address(groupDetails.address);
    self.coordinate(groupDetails.coordinate);
  }
}

export default GroupDetailsValidationModel;
