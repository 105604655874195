/**
 * Object to holds constants for the site to prevent the use of magic strings / magic values.
 */
const constants = {
  pageMode: {
    add: 'add',
    edit: 'edit'
  },
  uploadStatuses: {
    authorising: 'Authorising',
    pending: 'Pending',
    uploading: 'Uploading',
    uploaded: 'Uploaded',
    cancelled: 'Cancelled',
    failed: 'Failed',
    failedAuthorisation: 'FailedAuthorisation'
  },
  requestStatuses: {
    rejected: {
      value: 4
    }
  },
  uploadTypes: {
    file: 'file',
    cctvFolder: 'cctvFolder'
  },
  requestAssigneeTypes: {
    user: 0,
    group: 1
  },
  tabNames: {
    locationTab: 'Location',
    usersTab: 'Users'
  },
  preRegisteredUserIconClass: 'fa-user-slash',

  /**
   * The maximum number of uploads after which the camera name and comment inputs
   * are not displayed due to rendering performance issues.
   */
  maxUploadsForCameraNameAndCommentInputs: 250
};

export default constants;
