import ko from 'knockout';

/**
 * @param {object} serverModel
 * @param {string} previousRequestId
 */
function RequestSummaryModel(serverModel, previousRequestId) {

  var self = this;

  // Observables
  self.isRequestLastAccessed = ko.observable(serverModel.requestId === previousRequestId);

  // Standard properties
  self.requestId = serverModel.requestId;
  self.referenceNumber = serverModel.referenceNumber;
  self.requestTypeDescription = serverModel.requestTypeDescription;
  self.requestStatusForDisplay = serverModel.requestStatusForDisplay;
  self.requestPriorityForDisplay = serverModel.requestPriorityForDisplay;
  self.requestDateTime = serverModel.requestDateTime;
  self.agencyName = serverModel.agencyName;
  self.investigatorDisplayName = serverModel.investigatorDisplayName;
  self.requiredByDate = serverModel.requiredByDate;
  self.hasResponse = serverModel.hasResponse;
  self.responseTimestamp = serverModel.responseTimestamp;
  self.sentToRecipientList = serverModel.sentToRecipientList;
  self.collapsedListOptions = {
    list: self.sentToRecipientList,
    maxCollapsedListLength: 3,
    collapsibleLinkStringKey: 'RequestListCollapsibleLink',
    noItemsStringKey: 'CollapsibleListNoRecipients'
  };
  self.assignedPersonaName = serverModel.assignedPersonaName;
  self.assignedGroupName = serverModel.assignedGroupName;
  self.discussionMessagesCount = serverModel.discussionMessagesCount;
}

export default RequestSummaryModel;
