import UserModel from '../Models/userModel';
import repositoryHelper from './repositoryHelper';

function CurrentUserRepository() {

  var self = this;
  var baseResourceUrl = 'api/CurrentUser/';

  /**
   * @param {ErrorCode[]} errorCodesToIgnore Well-known error codes that if returned should not be logged
   * @return {Promise<unknown>}
   */
  self.getUserData = function (errorCodesToIgnore) {

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(baseResourceUrl, errorCodesToIgnore)
        .then(function (serverUserData) {
          var userData = new UserModel(serverUserData);
          resolve(userData);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  self.updateUserData = function (userData) {
    return new Promise(function (resolve, reject) {
      repositoryHelper.ajaxPut(baseResourceUrl, userData)
        .then(function (serverUserData) {
          var userData = new UserModel(serverUserData);
          resolve(userData);
        })
        .catch(function (jqXhr) {
          reject(jqXhr);
        });
    });
  };

  self.updateLanguageCode = function (languageCode) {
    console.debug(`Saving the language code '${languageCode}' for the user`);

    const resourceUrl = baseResourceUrl + 'languageCode';
    return repositoryHelper.ajaxPut(resourceUrl, languageCode);
  };
}

export default new CurrentUserRepository();
