import businessRepository from './Repositories/businessRepository';
import cameraRepository from './Repositories/cameraRepository';
import requestRepository from './Repositories/requestRepository';
import requestSummaryRepository from './Repositories/requestSummaryRepository';
import associatedFileRepository from './Repositories/associatedFileRepository';
import validationRulesRepository from './Repositories/validationRulesRepository';
import usersRepository from './Repositories/usersRepository';
import groupsRepository from './Repositories/groupsRepository';
import discussionRepository from './Repositories/discussionRepository';
import contextData from './contextData';
import constants from './constants';

var pageMode = constants.pageMode;

/**
 * Object containing all the routes for the SPA. The resolve property can be used to to define functions
 * that return promises, all of which have to be resolved before navigation to the new screen will occur.
 * This can be used to retrieve data and perform other actions before the next screen is rendered. The
 * page parameter specifies the page component to use and the pageMode parameter specifies the mode the
 * page should run under.
 */
var routes = [
  {
    url: '',
    params: { page: 'requests', helpId: '52601.htm', pageTitleKey: 'Requests' },
    resolve: {
      usePreviousViewSettings: params => Promise.resolve(params.previousRoute.page === 'request-form-switcher'),
      initialFilter: () => requestSummaryRepository.getInitialFilter()
    }
  },
  {
    url: 'requests',
    params: { page: 'requests', helpId: '52601.htm', pageTitleKey: 'Requests' },
    resolve: {
      usePreviousViewSettings: params => Promise.resolve(params.previousRoute.page === 'request-form-switcher'),
      initialFilter: () => requestSummaryRepository.getInitialFilter()
    }
  },

  {
    url: 'cameras', params: { page: 'cameras', helpId: '52604.htm', pageTitleKey: 'CamerasTitle' },
    resolve: {
      usePreviousViewSettings: params => Promise.resolve(params.previousRoute.page === 'camera-details'),
      // Wrapper function required so getCameras is called with no parameters
      allCameras: () => businessRepository.getCameras()
    }
  },

  // TODO: Have the router pass the initial component name to show
  {
    url: 'my-account/:component-name:',
    params: { page: 'my-account', helpId: '52772.htm', pageTitleKey: 'MyAccount' },
    resolve: {
      'user-details': () => validationRulesRepository.getValidationRules('user'),
      'business-details': () => validationRulesRepository.getValidationRules('business')
    }
  },

  {
    url: 'request/{id}', params: { page: 'request-form-switcher', helpId: '52602.htm', pageTitleKey: 'RequestDetails' },
    resolve: {
      request: params => requestRepository.getRequest(params.id),
      requestId: params => params.id,
      associatedFiles: params => associatedFileRepository.getAssociatedFileByRequestId(params.id),
      discussionMessages: params => discussionRepository.getMessagesByRequestId(params.id),
      cameraNameObjects: businessRepository.getCameraNames,
      requestAssignees: businessRepository.getRequestAssigneeSummaries
    }
  },
  {
    url: 'cameras/new/:lat:/:long:',
    params: { page: 'camera-details', pageMode: pageMode.add, helpId: '52605.htm', pageTitleKey: 'AddCameraTitle' },
    resolve: {
      validationRules: () => validationRulesRepository.getValidationRules('camera')
    }
  },
  {
    url: 'cameras/{id}',
    params: { page: 'camera-details', pageMode: pageMode.edit, helpId: '52639.htm', pageTitleKey: 'EditCameraTitle' },
    resolve: {
      camera: params => cameraRepository.getCamera(params.id)
    }
  },
  {
    url: 'users',
    params: { page: 'users', helpId: '52601.htm', pageTitleKey: 'UsersTitle' },
    resolve: {
      // Call get all users by current business Id
      allUsers: () => usersRepository.getAllUsers(contextData.userData.business.businessId),
      validationRules: () => validationRulesRepository.getValidationRules('businessuserupdate')
    }
  },
  {
    url: 'users/new',
    params: { page: 'business-user-details', pageMode: pageMode.add, helpId: '52601.htm', pageTitleKey: 'AddUserTitle' },
    resolve: {
      validationRules: () => validationRulesRepository.getValidationRules('businessusercreate')
    }
  },
  {
    url: 'groups',
    params: { page: 'groups', helpId: '52601.htm', pageTitleKey: 'GroupsTitle' },
    resolve: {
      // Call get all user groups by current business Id
      allGroups: () => groupsRepository.getAllGroups(contextData.userData.business.businessId),
      validationRules: () => validationRulesRepository.getValidationRules('group')
    }
  },
  {
    url: 'groups/new',
    params: { page: 'group-details', pageMode: pageMode.add, helpId: '52601.htm', pageTitleKey: 'AddGroupTitle' },
    resolve: {
      validationRules: () => validationRulesRepository.getValidationRules('group'),
      // Call all existed users for current business Id to create a new group
      allPersonas: () => usersRepository.getAllPersonaSummaries(contextData.userData.business.businessId)
    }
  },

  // The misc page has jump to anchor links (e.g. #misc_restext) so match anything starting with "misc"
  { url: /^misc/, params: { page: 'misc', pageTitleKey: 'Misc' } }
];

export default routes;
