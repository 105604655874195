/**
 * Well-known error codes enum for the error model.
 * This is the client-side version of the enum in Investigate.BusinessPortal.Enums.
 */
export enum ErrorCode {

  /**
   * Business is disabled
   * @type {ErrorCode.BusinessDisabled}
   */
  BusinessDisabled = 'BusinessDisabled',

  /**
   * The user has an account in an identity provider (e.g. SRP) but this user doesn't exist in the CBP
   * Users table therefore access is denied. If the user was sent a registration email via a request
   * from Investigate, they should be using this link to access the system / register for access.
   * @type {ErrorCode.UnknownUser}
   */
  UnknownUser = 'UnknownUser',

  /**
   * Business with the same name already exists
   * @type {ErrorCode.BusinessNameExists}
   */
  BusinessNameExists = 'BusinessNameExists'
}
